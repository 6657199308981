import Case from "case";
import { logEvent } from "firebase/analytics";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { MdAccessTime } from "react-icons/md";
import Link from "next/link";

import {
  ExploreSectionItemProp,
  MerchantDetailsProp,
} from "components/Explore/interface";
import ItemDetail from "components/ItemDetail";
import { Item } from "components/ItemDetail/interface";
import { Modal } from "components/Modal";
import { analytics } from "config/firebase.config";
import useModal from "hooks/useModal";
import { svgBlinking, toBase64 } from "utils/svgBlinking";
import { ExploreSectionProps } from "./interface";
import { formatCurrencyWithSymbol } from "utils/formats/number";
import Slick, { SlickSettings } from "components/Slick";

import * as S from "./styles";

const moneyFormatter = (money: number) => "$ " + (money / 100).toFixed(2);
const TitleCase = (title: string) => {
  return Case.capital(Case.lower(title));
};
let isOpen = false;

const getMerchantById = (merchantData: MerchantDetailsProp[], id: string) => {
  return merchantData.find((merchant) => {
    return merchant.id === id;
  });
};

const renderItems = (
  items: ExploreSectionItemProp[],
  type: string,
  merchantData: MerchantDetailsProp[],
  openItemDetail: any,
  isDragging: boolean
) => {
  return items.map((item: ExploreSectionItemProp) => {
    const merchant = getMerchantById(merchantData, item.merchant);
    const imageHeight = type === "sponsored_items" ? 123 : 122;

    const handleItemCategory = (itemCategory: string | string[]) => {
      const _itemCategory = Array.isArray(itemCategory)
        ? itemCategory?.join(", ")
        : itemCategory;

      return _itemCategory;
    };

    return (
      <S.Item
        key={item.name}
        type={type}
        className={type}
        onClick={() => {
          logEvent(analytics!, "view_item_featured", {
            itemId: item.itemId,
            itemName: item.name,
            merchant: item.merchant,
            itemCategory: handleItemCategory(item.categories),
          });

          !isDragging && openItemDetail(item);
        }}
      >
        <S.ItemImage
          type={type}
          src={item.featuredPicture}
          width="480"
          height={imageHeight}
          objectFit="cover"
          alt={item.name}
          placeholder="blur"
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            svgBlinking("100%", imageHeight)
          )}`}
        />
        <S.ItemInfoWrapper>
          {type === "sponsored_items" && (
            <S.PriceInfo>
              {item?.price > 0 && (
                <S.MerchantPrice>{moneyFormatter(item?.price)}</S.MerchantPrice>
              )}
            </S.PriceInfo>
          )}
          <S.ItemInfo>
            {item.campaigns?.length > 0 && type !== "sponsored_items" && (
              <S.DiscountItemTag>
                {console.log(item)}
                <img
                  src="/img/icons/one-color/voucher.svg"
                  width={16}
                  height={16}
                />

                <p>
                  Spend{" "}
                  {formatCurrencyWithSymbol(
                    item.campaigns[0].minimumOrderValue / 100
                  )}
                  , save{" "}
                  {formatCurrencyWithSymbol(
                    item.campaigns[0].voucherShift / 100
                  )}
                </p>

                <span />
                <span />
                <span />
              </S.DiscountItemTag>
            )}
            <S.ItemName type={type}>{TitleCase(item.name)}</S.ItemName>
            {type !== "sponsored_items" && (
              <S.MerchantCookTime>
                <MdAccessTime />
                <div>
                  {merchant?.cookingTime}-{merchant?.cookingTime || 30 + 10} min
                </div>
              </S.MerchantCookTime>
            )}
          </S.ItemInfo>
          <S.MerchantName type={type}>{merchant?.name}</S.MerchantName>
          {type === "sponsored_items" && (
            <S.CookingInfo>
              <S.MerchantCookTime>
                <MdAccessTime />
                <div>
                  {merchant?.cookingTime}-{(merchant?.cookingTime || 30) + 10}{" "}
                  min
                </div>
              </S.MerchantCookTime>
            </S.CookingInfo>
          )}
        </S.ItemInfoWrapper>
      </S.Item>
    );
  });
};

const renderItemDetail = (
  item: any,
  options: any,
  onClose: () => void,
  modalIsOpen: boolean
) => {
  return (
    <Modal widthAuto open={modalIsOpen} onClose={onClose}>
      <ItemDetail itemProp={item} optionsProp={options} close={onClose} />
    </Modal>
  );
};

const settings: SlickSettings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
      },
    },
  ],
};

const settingsYouMayAlsoLike: SlickSettings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 8,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4.2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3.2,
      },
    },
  ],
};

const ExploreSection = ({
  sectionItem,
  merchantData,
  options,
  item,
  onItemSelect,
}: ExploreSectionProps) => {
  const [showItemDetail, setShowItemDetail] = useState(false);
  const [currentItem, setCurrentItem] = useState<Item | null>(item);
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal();
  const router = useRouter();

  const openItemDetail = (item: any) => {
    let newItem = JSON.parse(JSON.stringify(item));
    setCurrentItem({ ...newItem, categoryName: Case.upper(sectionItem.title) });
    router.push(`?item=${item.itemId}`, undefined, { shallow: true });
    onItemSelect({ ...newItem, categoryName: Case.upper(sectionItem.title) });
    setShowItemDetail(true);
    setModalIsOpen(true);
    const main = document.querySelector(".mainwrapper") as HTMLElement;
    if (main) main.style.overflow = "hidden";
  };

  const closeItemDetail = () => {
    const main = document.querySelector(".mainwrapper") as HTMLElement;
    if (main) main.style.overflow = "auto";
    router.replace(`/`, undefined, { shallow: true });
    setCurrentItem(null);
    onItemSelect(null);
    setShowItemDetail(false);
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (currentItem && !isOpen) {
      setShowItemDetail(true);
      setModalIsOpen(true);
      isOpen = true;
    }
  }, []);

  const [isDragging, setIsDragging] = useState(false);
  const pointer = useRef({ x: 0, y: 0 });

  const onMouseDown = (e: any) => {
    pointer.current = { x: e.clientX, y: e.clientY };
    setIsDragging(true);
  };

  const onMouseUp = (e: any) => {
    const { x, y } = pointer.current;
    if (Math.abs(e.clientX - x) < 10 && Math.abs(e.clientY - y) < 10) {
      setIsDragging(false);
    }
  };

  const getCategoryLink = (type: string) => {
    return type.replaceAll(`_`, `-`);
  };

  return (
    <S.Wrapper onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
      <S.Title>
        {sectionItem.title}
        <Link href={`/${getCategoryLink(sectionItem.type)}`}>
          <S.LinkWrapper>
            See all
            <S.CategoryType>{` ${Case.lower(
              sectionItem.title
            )}`}</S.CategoryType>
            <img
              src="/img/icons/one-color/arrow-right.svg"
              alt="Arrow right icon"
            />
          </S.LinkWrapper>
        </Link>
      </S.Title>

      <S.ItemContainer>
        <Slick
          settings={
            sectionItem.type === "sponsored_items"
              ? settingsYouMayAlsoLike
              : settings
          }
        >
          {renderItems(
            sectionItem.items,
            sectionItem.type,
            merchantData,
            openItemDetail,
            isDragging
          )}
        </Slick>
      </S.ItemContainer>

      {showItemDetail &&
        renderItemDetail(currentItem, options, closeItemDetail, modalIsOpen)}
    </S.Wrapper>
  );
};

export default ExploreSection;
