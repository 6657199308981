import Link from "next/link";
import { useRouter } from "next/router";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Ripples from "react-ripples";

import { formatCurrencyWithSymbol } from "utils/formats/number";
import { svgBlinking, toBase64 } from "utils/svgBlinking";
import ButtonGroup from "../ButtonGroup";
import { MagicDealsProps } from "./interface";

import * as G from "../../styles/global";
import * as S from "./styles";
import useModal from "hooks/useModal";
import UseVoucherModal from "components/UseVoucherModal";
import { Modal } from "components/Modal";
import { useState } from "react";
import api from "services/api";

const BG_COLORS = {
  0: "#FF4C4C",
  1: "#FBA240",
  2: "#39393F",
};

const TAG_COLORS = {
  0: "#FBA240",
  1: "#FF4C4C",
  2: "#FBA240",
};

const renderItems = (
  deals: MagicDealsProps[],
  openDeal: (deal: MagicDealsProps, color: number) => void
) => {
  console.log(deals);

  return (
    deals.length > 0 &&
    deals.map((deal, index) => {
      const rippleWidth = "100%";
      const indexBgColor = index % 3;
      const indexTagColor = index % 3;
      const bgColor = BG_COLORS[indexBgColor as keyof typeof BG_COLORS];
      const tagColor = TAG_COLORS[indexTagColor as keyof typeof TAG_COLORS];

      return (
        <G.RipplesItemContainer key={deal.id} width={rippleWidth}>
          <Ripples>
            <S.Item
              bgColor={bgColor}
              onClick={() => openDeal(deal, indexBgColor)}
            >
              <S.ItemMerchantWrapper>
                <S.ItemTagOffer tagColor={tagColor}>
                  Spend ${deal.minimumOrder / 100}, Save{" "}
                  ${deal.voucherAmount / 100}
                  <span />
                  <span />
                  <span />
                </S.ItemTagOffer>

                <S.ItemMerchantInfo>
                  <S.ItemLogo
                    src={deal.logo}
                    width="32"
                    height="32"
                    alt={deal.title}
                    objectFit="contain"
                  />

                  <h1>{deal.merchant.name}</h1>
                </S.ItemMerchantInfo>
              </S.ItemMerchantWrapper>

              <S.ItemImage
                src={deal.merchant.picture}
                width="138"
                height="166"
                alt={deal.title}
                placeholder="blur"
                objectFit="cover"
                blurDataURL={`data:image/svg+xml;base64,${toBase64(
                  svgBlinking(138, 166)
                )}`}
              />
            </S.Item>
          </Ripples>
        </G.RipplesItemContainer>
      );
    })
  );
};

const renderUseVoucherModal = (
  open: any,
  toggle: any,
  deal: MagicDealsProps | undefined,
  currentColor: number,
  getDeals: () => Promise<void>
) => {
  return (
    deal && (
      <Modal title="" open={open} onClose={() => toggle(false)}>
        <UseVoucherModal
          deal={deal}
          close={() => {
            getDeals();
            toggle(false);
          }}
          color={currentColor}
        />
      </Modal>
    )
  );
};

type MagicDealsExploreProps = {
  dealsProp: MagicDealsProps[];
  updateDeals: () => Promise<void>;
};

const MagicDealsExplore = ({
  dealsProp,
  updateDeals,
}: MagicDealsExploreProps) => {
  const { open: modalIsOpen, toggle: setModalIsOpen } = useModal();
  const [currentDeal, setCurrentDeal] = useState<MagicDealsProps>();
  const [currentColor, setCurrentColor] = useState<number>(0);
  const [deals, setDeals] = useState(dealsProp || []);

  const getDeals = async () => {
    await updateDeals();
  };

  return (
    <S.Wrapper>
      <S.Title>
        Magic Deals
        <Link href="/profile/magic-deals">
          <S.LinkWrapper>
            See all deals
            <img
              src="/img/icons/one-color/arrow-right.svg"
              alt="Arrow right icon"
            />
          </S.LinkWrapper>
        </Link>
      </S.Title>

      <Carousel
        containerClass="carousel-container"
        itemClass="magic-deals-items"
        responsive={{
          superLargeDesktop: {
            breakpoint: { max: 4000, min: 1400 },
            items: 4,
            slidesToSlide: 4,
            partialVisibilityGutter: 0,
          },
          desktop: {
            breakpoint: { max: 1399, min: 1024 },
            items: 4,
            slidesToSlide: 4,
            partialVisibilityGutter: 0,
          },
          tablet: {
            breakpoint: { max: 1023, min: 769 },
            items: 6,
            slidesToSlide: 3,
            partialVisibilityGutter: 0,
          },
          mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 0,
          },
        }}
        partialVisible={true}
        arrows={false}
      >
        {renderItems(deals, (deal, color) => {
          setCurrentDeal(deal);
          setCurrentColor(color);
          setModalIsOpen(true);
        })}
        {modalIsOpen &&
          renderUseVoucherModal(
            modalIsOpen,
            setModalIsOpen,
            currentDeal,
            currentColor,
            getDeals
          )}
      </Carousel>
    </S.Wrapper>
  );
};

export default MagicDealsExplore;
