import Image from "next/image";
import styled from "styled-components";
import { Theme } from "styles/theme";
import { ItemMagicDealProps, ItemTagOfferMagicDealProps } from "./interface";

export const Wrapper = styled.section`
  margin-top: 28px;
  position: relative;

  .carousel-container {
    width: 100%;
    height: 190px;
    z-index: 2;
  }

  .magic-deals-items {
    max-width: 270px;
    min-width: 270px;
    height: 165px;
    margin-right: 16px;
  }

  @media (min-width: 768px) {
    .magic-deals-items {
      max-width: 305px;
      min-width: 305px;
    }
  }

  .react-multi-carousel-item {
    margin-left: 16px;

    @media (min-width: 768px) {
      margin-left: 0px;
      margin-right: 16px;
    }
  }
`;

export const Title = styled.h1`
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: ${Theme.colors.tundora};
  margin-left: 16px;

  @media (min-width: 768px) {
    margin-left: 0px;
  }

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  a {
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    text-decoration: none;
    color: ${Theme.colors.sunsetOrange};
  }

  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const LinkWrapper = styled.span`
  font-family: "Quicksand", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  text-decoration: none;
  color: ${Theme.colors.sunsetOrange};
  cursor: pointer;
  margin-right: 16px;

  @media (min-width: 768px) {
    margin-right: 0px;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Item = styled.div<ItemMagicDealProps>`
  height: 166px;
  border-radius: 16px;
  box-shadow: 1px 2px 7px 0 rgba(67, 61, 61, 0.09);
  background-color: ${({ bgColor }) => bgColor};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ItemMerchantWrapper = styled.div``;

export const ItemTagOffer = styled.div<ItemTagOfferMagicDealProps>`
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  font-weight: 600;
  width: 140px;
  padding: 5px 0 5px 16px;
  margin: 16px 0 12px 0;
  background-color: ${({ tagColor }) => tagColor};
  color: #ffffff;
  position: relative;

  span {
    position: absolute;
    top: 0;
    right: -6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: inherit;

    &:nth-child(1) {
      top: 7.5px;
    }

    &:nth-child(2) {
      top: 15px;
    }
  }
`;

export const ItemMerchantInfo = styled.div`
  margin-left: 16px;
  max-width: 150px;
  height: 104px;
  overflow: hidden;

  h1 {
    font-family: "Quicksand", sans-serif;
    font-size: 32px;
    line-height: 30px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 6px;
  }
`;

export const ItemLogo = styled(Image)`
  border-radius: 16px;
  background-color: #ffffff;
`;

export const ItemImage = styled(Image)`
  border-top-left-radius: 91px;
  border-bottom-left-radius: 91px;
  background-color: #ffffff;
`;
