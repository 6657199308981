import Image from "next/image";
import styled from "styled-components";
import { Theme } from "styles/theme";
import { ItemStyleProps } from "./interface";

export const ItemContainer = styled.div`
  margin: 16px 0 0 0;
  width: 100%;
  display: flex;

  @media (min-width: 768px) {
    width: 304px;
    margin: 16px 16px 0 0;
  }

  .react-ripples {
    width: 100%;
  }
`;

export const UnstyledLink = styled.a`
  text-decoration: none;
  width: 100%;
  display: flex;
`;

export const Item = styled.div<ItemStyleProps>`
  width: 100%;
  height: 164px;
  padding: 8px 19px 8px 8px;
  border-radius: 24px;
  box-shadow: 1px 2px 7px 0 rgba(67, 61, 61, 0.09);
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    width: 305px;
  }

  ${(props) => {
    if (props.isClosed || props.isClosed === "unavailable") {
      return `
      opacity: 0.5;`;
    }
  }};

  &:nth-child(4n) {
    margin-right: 0;
  }
`;

export const ItemLoyaltyTag = styled.div`
  position: absolute;
  bottom: 0;
  right: 15px;
  background-image: url("/img/loyalty-merchant-tag.svg");
  width: 38px;
  height: 36px;
`;

export const ItemInfo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;
`;

export const ItemSubInfo = styled.div``;

export const ItemTitle = styled.div`
  margin: 4px 0 2px 8px;
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: ${Theme.colors.tundora};
`;

export const ItemCategory = styled.div`
  margin: 4px 0 2px 8px;
  font-family: "Quicksand";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: ${Theme.colors.gray};
`;

export const CookingTime = styled.div`
  margin: 8px 0 0 8px;
  font-family: "Quicksand";
  font-size: 12px;
  line-height: 14px;
  color: ${Theme.colors.gray};
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

export const ItemStatus = styled.div`
  color: #43cb8f;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  font-family: "Quicksand";
`;

export const ItemLogo = styled(Image)`
  width: 82px;
  max-height: 110px;
  object-fit: scale-down;
`;
